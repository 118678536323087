/*
 * @Author: your name
 * @Date: 2022-03-15 22:30:14
 * @LastEditTime: 2022-05-15 18:45:43
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\plugin\index.js
 */
import { useIntersectionObserver } from '@vueuse/core'
import { sleep, imgLoad } from '@/utils/public'
import Loading from '@/components/loading/loading.vue'
import { createApp } from 'vue'

export default function (app) {
  app.directive('seen', {
    mounted(el, binding) {
      let realSrc = null //储存真实图片地址
      let instance = null //储存真实图片地址
      // 预执行
      switch (binding.value.type) {
        // 懒加载
        case 'lazy':
          // 储存真实地址
          realSrc = el.src
          el.src = ''
          instance = createApp(Loading).mount(document.createElement('div'))
          el.parentNode.style.position = 'relative'
          instance.$el.style.position = 'absolute'
          instance.$el.style.width = '100%'
          if(binding.value.height){
            instance.$el.style.height = binding.value.height
          }
          // let component = new tipComponent().$mount();
          // var DomContent = component.$el; //将vue结构转化成dom
          el.parentNode.appendChild(instance.$el)
          // 修改图片宽度，因为源地址图片宽度是100%，假图片没那么大所以不能撑满元素
          break
        default:
          break
      }

      const { stop } = useIntersectionObserver(
        el,
        // isIntersecting 是否进入可视区域，true是进入 false是移出
        // observerElement 被观察的dom
        async ([{ isIntersecting }], observerElement) => {
          // 进入可视区域执行
          if (isIntersecting) {
            switch (binding.value.type) {
              // 懒加载
              case 'lazy':
                // 延迟500毫秒，可以用settimeout代替
                await sleep(500)
                // 图片元素地址设置为真的地址
                el.src = realSrc
                // 监听图片加载完成把宽度设置回100%
                imgLoad(el).then(res => {
                  el.parentNode.removeChild(instance.$el)
                })
                break
              // css动画
              case 'animate':
                // 给当前节点添加class
                el.classList.add('animate__animated', binding.value.class)
                break
              default:
                break
            }
            // 停止监听滚动
            stop()
          }
        }
      )
    },
  })

  app.directive('waterFall', {
    mounted(el, binding) {
      // console.log(el.offsetHeight); //当前元素高度
      // console.log(binding.value.heightArr); //ref数组
      console.log(window.getComputedStyle(el)['padding-left'])
      let marginWidth =
        Number(window.getComputedStyle(el)['padding-left'].split('px')[0]) * 2
      let heightArr = binding.value.heightArr
      let leftArr = binding.value.leftArr
      el.style.width = '40%'
      el.style.position = 'absolute'
      if (heightArr.length < 2) {
        el.style.top = '0px'
        el.style.left = el.offsetWidth * heightArr.length + marginWidth + 'px'
        binding.value.waterFall(
          el.offsetHeight + marginWidth,
          el.offsetWidth * (heightArr.length - 1)
        )
      } else {
        var minHeight = heightArr[0]
        var index = 0
        for (var j = 0; j < heightArr.length; j++) {
          if (minHeight > heightArr[j]) {
            minHeight = heightArr[j]
            index = j
          }
        }
        el.style.top = heightArr[index] + marginWidth * 2 + 'px'
        if (index == 0) {
          el.style.left = marginWidth + 'px'
        } else {
          el.style.left = `calc(40% + ${marginWidth * 2}px)`
        }
        heightArr[index] = heightArr[index] + el.offsetHeight + marginWidth * 2
      }

      // heightArr.findIndex(v=>)

      // el.style.position = 'absolute'
    },
  })
}
