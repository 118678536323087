<template>
  <el-affix :offset="0">
    <div
      class="header"
      :style="{ height: flexScreen == 'mob' ? '60px' : '70px' }"
    >
      <el-row>
        <!-- 左 -->
        <el-col :span="6">
          <div
            class="head_left animate__animated animate__slideInLeft"
            @click="UP_LOGO('Bay-max')"
          >
            <slot
              name="head_left"
              v-if="!isPosts && !isSearch && !isphoDetail && !isUser"
            ></slot>
            <div
              class="back"
              v-if="isPosts || isSearch || isphoDetail || isUser"
            >
              <el-button
                type="text"
                :icon="Back"
                @click="toIndex()"
              ></el-button>
            </div>
          </div>
        </el-col>
        <!-- 中 -->
        <el-col :span="12">
          <div class="head_link">
            <div v-if="!isPosts && !isSearch && !isphoDetail && !isUser">
              <div
                :class="{
                  link_item: true,
                  'active-link': item.name == active_link,
                }"
                v-for="(item, index) in links"
                :key="index"
                @click="changeLink(item.name, item.path)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-col>
        <!-- 右 -->
        <el-col :span="6">
          <div class="head_right">
            <!-- 全局工具 -->
            <div class="globalTool">
              <el-icon
                class="globalTool_item"
                :size="20"
                :color="theme == 'light' ? '#666' : '#fff'"
                @click="changeTheme(theme)"
              >
                <Moon v-if="theme == 'light'" />
                <Sunny v-else />
              </el-icon>
              <Search v-if="isIndex || isSearch" />
            </div>
            <!-- 文章工具 -->
            <ArticleTool
              class="articleTool"
              @likeFn="likeFn(null, 'posts')"
              @pingFn="pingFn"
              v-if="isPosts"
              :likeNum="likeNum"
              :commentNum="commentNum"
              :isLikedMe="isLikedMe"
            >
            </ArticleTool>

            <!-- 用户工具 pc -->
            <div v-if="screenWidth > 600" class="userTool">
              <el-button
                size="small"
                v-if="!isLogin"
                @click="UP_LOGINSHOW(true)"
              >
                登陆
              </el-button>
              <el-popover
                v-else
                placement="bottom"
                :width="150"
                trigger="click"
                v-model:visible="popoverFlag"
              >
                <template #reference>
                  <el-avatar
                    size="default"
                    :src="avatar"
                    @click="popoverFlag = !popoverFlag"
                  ></el-avatar>
                  <div>{{ username }}</div>
                </template>
                <div class="head_right_tool">
                  <div class="head_right_item" @click="toUser()">个人中心</div>
                  <div
                    class="head_right_item"
                    @click="ACT_UP_ISLOGIN(!isLogin)"
                  >
                    退出
                  </div>
                </div>
              </el-popover>
            </div>
            <!-- 用户工具 mob -->
            <div v-else class="userTool">
              <el-icon
                :size="20"
                :style="{
                  color: 'var(--white_divFc)',
                }"
              >
                <Expand @click="openMobDrawer"></Expand>
              </el-icon>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-affix>

  <!-- mob drawer -->
  <el-drawer
    v-if="screenWidth <= 600"
    v-model="drawer"
    title="I am the title"
    :with-header="true"
    size="60%"
    direction="ltr"
    :show-close="false"
  >
    <template #title>
      <h4 class="mob_title" v-show="isLogin">
        {{ getTimeState() }}，{{ username }}！
      </h4>
      <h4 class="mob_title" v-show="!isLogin">
        {{ getTimeState() }}，未登录！
      </h4>
    </template>
    <template #default>
      <div class="mob_body">
        <div class="mob_userInfo">
          <div class="user_avatar">
            <el-avatar :size="70" :src="avatar" @click="toUser()"></el-avatar>
          </div>
        </div>
        <div class="mob_links">
          <div class="mob_linksItem">
            <div
              :class="{
                link_item: true,
                'active-link': item.name == active_link,
              }"
              v-for="(item, index) in links"
              :key="index"
              @click="changeLink(item.name, item.path)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="mob_footer">
        <el-button
          size="small"
          type="text"
          v-if="!isLogin"
          @click="UP_LOGINSHOW(true)"
        >
          登陆
        </el-button>

        <el-button
          size="small"
          type="text"
          v-else
          @click="ACT_UP_ISLOGIN(!isLogin)"
        >
          退出登陆
        </el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import { Back, Expand, Moon, Sunny } from "@element-plus/icons";
import { mapMutations, useStore, mapActions } from "vuex";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGetters } from "@/store/utils/useGetters.js";
import ArticleTool from "../articleTool/articleTool.vue";
import Search from "../search/search.vue";
import useLike from "./useLike";
import useScreen from "@/hooks/useScreen.js";
import { isLikedFn } from "@/api/api";
import { getTimeState } from "@/utils/public";
import usePath from "@/hooks/usePath.js";
import { isLogin, updateLoginShow } from "@/utils/user.js";

export default {
  name: "Header",
  props: ["links"],
  components: {
    ArticleTool,
    Search,
  },
  setup(props) {
    let store = useStore();
    let route = useRoute();
    let router = useRouter();
    // 获取当前所在页面
    let { isPosts, isIndex, isSearch, isphoDetail, isUser } = usePath();

    // 评论点赞
    let useLikes = useLike();
    let { screenWidth, flexScreen } = useScreen();

    // 储存上一个路由地址
    let oldPath = ref(null);
    let nowPath = ref(null);
    // mob drawer控制
    let drawer = ref(false);

    // 修改store中当前url地址
    let changeLink = (name, url) => {
      // if (url == "/links") {
      //   return;
      // }
      store.commit("headInfo/UP_ACTIVE_LINK", name);
      drawer.value = false;
      router.push({
        path: url,
      });
    };

    // 打开 drawer
    let openMobDrawer = () => {
      drawer.value = true;
    };

    // 更换主题
    let changeTheme = (e) => {
      if (e == "dark") {
        store.commit("headInfo/UP_THEME", "light");
      } else {
        store.commit("headInfo/UP_THEME", "dark");
      }
    };

    // 去首页
    let toIndex = () => {
      // 上一个页面不存在的话
      if (oldPath.value == "/" || !oldPath.value) {
        console.log(nowPath.value);
        // 如果当前地址是图片详情
        if (isphoDetail.value) {
          router.push({
            path: `/photo`,
          });
        } else if (isSearch.value) {
          router.push({
            path: `/index`,
          });
        }
        // 否则返回主页
        else {
          router.push({
            path: `/index`,
          });
        }
      }
      // 上一个页面不存在的话
      else {
        router.back();
      }
    };

    // 监听登陆状态 根据当前路由执行某些事件
    watch(
      () => store.state.userInfo.isLogin,
      async (newValue, oldValue) => {
        // 文章详情页
        if (isPosts.value) {
          if (newValue) {
            let postId = route.params.id;
            // 获取点赞状态
            let { data } = await isLikedFn({ id: Number(postId), type: 1 });
            useLikes.updatePostList({
              id: postId,
              filed: "isLikedMe",
              value: !!data,
            });
          }
        }
      },
      {
        deep: true,
      }
    );

    // 监听路由，获取上一个路由
    watch(
      () => route.path,
      async (newValue, oldValue) => {
        oldPath.value = oldValue;
        nowPath.value = newValue;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    let popoverFlag = ref(false);

    let toUser = () => {
      if (!isLogin()) {
        updateLoginShow(true);
        return;
      }
      drawer.value = false;
      popoverFlag.value = false;
      router.push({ path: "/user" });
    };

    return {
      popoverFlag,
      toUser,
      changeTheme,
      openMobDrawer,
      screenWidth,
      flexScreen,
      getTimeState,
      drawer,
      ...useLikes,
      Back,
      Expand,
      Moon,
      Sunny,
      isPosts,
      isIndex,
      isSearch,
      isphoDetail,
      isUser,
      changeLink,
      toIndex,
      ...useGetters("headInfo", ["logo", "active_link", "theme"]),
      ...useGetters("userInfo", ["isLogin", "username", "avatar"]),
      ...mapMutations("headInfo", ["UP_LOGO", "UP_THEME"]),
      ...mapMutations("userInfo", ["UP_ISLOGIN", "UP_LOGINSHOW"]),
      ...mapActions("userInfo", ["ACT_UP_ISLOGIN"]),
    };
  },
};
</script>

<style lang="scss">
.mob_title {
  color: var(--white_divFc) !important;
}
.mob_body {
  .el-avatar {
    background-color: rgba(82, 154, 236, 0.05);
  }
  // border: 1px solid #eee;
  background-color: rgba(82, 154, 236, 0.05);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .mob_userInfo {
    flex: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mob_links {
    flex: 2;
    .mob_linksItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 10px;
      box-sizing: border-box;
      .link_item:hover {
        background-color: var(--white_divHovc) !important ;
      }
      .link_item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        color: var(--white_divFc) !important;
        border-radius: 10px;
      }

      .active-link {
        color: #000;
        font-weight: 600;
        border-bottom: none !important;
        background-color: var(--white_divBgc);
      }
    }
  }
}
.el-drawer {
  background-color: var(--white_divBgc) !important;
}
.el-drawer__header {
  margin-bottom: 10px !important;
}

.mob_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  background-color: rgba(82, 154, 236, 0.05) !important;

  overflow: hidden;
  .el-button {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: var(--white_divFc);
  }
}
.el-affix {
  width: auto !important;
}

.globalTool {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.globalTool_item {
  padding: 8px 12px !important;
  border-radius: 3px;
}
.globalTool_item:hover {
  background-color: var(--white_divHovc) !important;
}

.articleTool {
  margin-right: 10px;
  flex: 2;
  /* border: 1px solid green; */
}

.userTool {
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  .el-button {
    padding: 10px !important;
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: var(--white_divFc);
    background-color: var(--white_divBgc);

    border-radius: 5px !important;
    &:hover {
      background-color: var(--white_divBgc);
      color: var(--white_divFc);
      border: 1px solid var(--white_divFc);
    }
    &:link {
      background-color: var(--white_divBgc);
      color: var(--white_divFc);
      border: 1px solid var(--white_divFc);
    }
  }
}

.el-popover,
.el-popper__arrow {
  background-color: var(--white_divBgc) !important;
  border: 1px solid var(--white_divBgc) !important;
}
.el-popper.is-light .el-popper__arrow::before {
  background: var(--white_divBgc) !important;
  border: var(--white_divBgc) !important;
  right: 0;
}

.head_right_tool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--white_divBgc);
  // border: var(--white_divBgc) !important;
}
.head_right_item {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80%;
  padding: 5px 0;
  border-radius: 8px;
  background-color: var(--white_divBgc);
  color: var(--white_divFc);
}

.head_right_item:hover {
  background-color: var(--white_divHovc);
}

.back .el-icon {
  color: var(--white_divFc);
  font-size: 22px !important;
}

.header {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--white_divBgc);
}

.head_left,
.head_link,
.head_right {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white_divFc);
}

.head_left {
  font-family: "UniNeue" !important;
  font-weight: 600;
  font-size: 18px;
  justify-content: flex-start;
  padding-left: 20px;
}

.head_right {
  justify-content: flex-end;
  padding-right: 70px;
  cursor: pointer;
  /* border: 1px solid #333; */
}

.head_link {
  box-sizing: border-box;
}

.link_item {
  padding: 10px 20px;
  margin-left: 2px;
  display: inline-block;
  cursor: pointer;
  color: var(--white_divFc) !important;
}

.link_item:hover {
  background-color: var(--white_divHovc);
}

.active-link {
  color: var(--white_divFc);
  font-weight: 600;
  border-bottom: 2px solid #ff5500;
}

.el-row {
  height: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  height: 100%;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .head_right {
    padding-right: 20px;
    cursor: none !important;

    /* border: 1px solid #333; */
  }
  .head_link {
    display: none;
  }

  .head_right_tool {
    cursor: none !important;
  }
}
</style>
