/*
 * @Author: your name
 * @Date: 2022-03-15 19:11:00
 * @LastEditTime: 2022-03-31 14:50:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\utils\storage.js
 */

export let getStorage = (name) => {
  let res = window.localStorage.getItem(name)
  return res
}
 

export let setStorage = (name,value) => {
  let res = window.localStorage.setItem(name,value)
  return res
}


export let removeStorage = (name) => {
  let res = window.localStorage.removeItem(name)
  return res
}