/*
 * @Author: your name
 * @Date: 2022-03-29 15:10:53
 * @LastEditTime: 2022-05-04 21:37:33
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\hooks\usePath.js
 */
import { watch, ref, toRefs, reactive } from 'vue'
import { useRoute } from 'vue-router'

export default function () {
  let route = useRoute()
  let nowPath = reactive({
    isIndex: false,
    isPosts: false,
    isTag: false,
    isGuestbook: false,
    isPhoto: false,
    isSearch: false,
    isphoDetail: false,
    isUser: false,
    isNoFound: false,
  })

  let is = keys => {
    console.log(keys)
    for (const key in nowPath) {
      if (Object.hasOwnProperty.call(nowPath, key)) {
        if (key == keys) {
          nowPath[key] = true
        } else {
          nowPath[key] = false
        }
      }
    }
  }

  // 监听路由，获取上一个路由
  watch(
    () => route.path,
    async (newValue, oldValue) => {
      switch (true) {
        case newValue == '/404':
          is('isNoFound')
          break
        case newValue.indexOf('/user') >= 0:
          is('isUser')
          break
        case newValue.indexOf('/phoDetail') >= 0:
          is('isphoDetail')
          break
        case newValue.indexOf('/index') >= 0 || '/':
          is('isIndex')
          break
        case newValue.indexOf('/posts') >= 0:
          is('isPosts')
          break
        case newValue.indexOf('/tag') >= 0:
          is('isTag')
          break
        case newValue.indexOf('/guestbook') >= 0:
          is('isGuestbook')
          break
        case newValue.indexOf('/photo') >= 0:
          is('isPhoto')
          break
        case newValue.indexOf('/search') >= 0:
          is('isSearch')
          break
        default:
          break
      }
    },
    {
      immediate: true,
      deep: true,
    }
  )

  return toRefs(nowPath)
}
