import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { removeStorage } from '../utils/storage'
import SecureLS from 'secure-ls'
var ls = new SecureLS({
  encodingType: 'aes', //加密类型
  isCompression: false,
  encryptionSecret: 'encryption', //PBKDF2值
})

const headerInfo = {
  namespaced: true,
  state: () => ({
    logo: 'baymax',
    theme: 'light',
    links: [
      {
        name: '首页',
        path: '/index',
      },
      {
        name: '留言',
        path: '/guestbook',
      },
      // {
      // 	name: '友邻',
      // 	path: '/links'
      // },
      {
        name: '图库',
        path: '/photo',
      },
    ],
    active_link: '',
  }),
  mutations: {
    UP_THEME(state, value) {
      console.log('aaaa', value)
      state.theme = value
    },
    UP_LOGO(state, value) {
      state.logo = value
    },
    UP_LINKS(state, value) {
      state.links = value
    },
    UP_ACTIVE_LINK(state, value) {
      state.active_link = value
    },
  },
  actions: {},
  getters: {
    theme: state => {
      return state.theme
    },
    logo: state => {
      return state.logo
    },
    links: state => {
      return state.links
    },
    active_link: state => {
      return state.active_link
    },
  },
}

const userInfo = {
  namespaced: true,
  state: () => ({
    token: '',
    loginShow: false,
    isLogin: false,
    username: 'Bay-max',
    avatar: './noLogin.png',
  }),
  mutations: {
    UP_LOGINSHOW(state, value) {
      state.loginShow = value
    },
    // 修改登录状态
    UP_ISLOGIN(state, value) {
      if (!value) {
        state.isLogin = value
        state.token = ''
        state.username = ''
        state.avatar = './noLogin.png'
        removeStorage('token')
        removeStorage('_secure__ls__metadata')
        removeStorage('encryptionStore')
        window.location.reload()
      } else {
        state.isLogin = value
      }
    },
    UP_USERNAME(state, value) {
      state.username = value
    },
    UP_AVATAR(state, value) {
      state.avatar = value
    },
    UP_TOKEN(state, value) {
      state.token = value
    },
  },
  actions: {
    // 清空 PostList
    ACT_UP_ISLOGIN({ commit }, value) {
      // 退出登陆
      commit('UP_ISLOGIN', value)
      // 清空缓存文章列表
      commit(
        'postInfo/UP_postsList',
        { fileds: { type: 'clear' } },
        { root: true }
      )
      // 清空点赞缓存
      commit('postInfo/UP_myLikeList', { type: 'clear' }, { root: true })
      // 清空导航缓存
      commit(
        'headInfo/UP_LINKS',
        [
          {
            name: '首页',
            path: '/index',
          },
          {
            name: '留言',
            path: '/guestbook',
          },
          // {
          // 	name: '友邻',
          // 	path: '/links'
          // },
          {
            name: '图库',
            path: '/photo',
          },
        ],
        { root: true }
      )
    },
  },
  getters: {
    loginShow: state => {
      return state.loginShow
    },
    isLogin: state => {
      return state.isLogin
    },
    username: state => {
      return state.username
    },
    avatar: state => {
      return state.avatar
    },
    token: state => {
      return state.token
    },
  },
}

const postInfo = {
  namespaced: true,
  state: () => ({
    // 判断是否通过评论按钮进入页面从而滚动页面
    isFromComment: false,
    // 来自哪个评论id
    fromCommentId: null,
    // 文章列表缓存
    postsList: [],
    // 当前所在的文章id
    postId: null,
    // 点过赞的文章或者评论、留言
    myLikeList: {
      posts: [],
      comments: [],
    },
    // 获取已经过的文章列表id
    postIdList: [],
  }),
  getters: {
    postIdList: state => {
      return state.postIdList
    },
    isFromComment: state => {
      return state.isFromComment
    },
    fromCommentId: state => {
      return state.fromCommentId
    },
    postsList: state => {
      return state.postsList
    },
    postId: state => {
      return state.postId
    },
    myLikeList: state => {
      return state.myLikeList
    },
  },
  mutations: {
    UP_postIdList(state, obj) {
      if (obj.type == 'add') {
        state.postIdList.push(obj.value)
      }
      if (obj.type == 'clear') {
        state.postIdList = []
      }
    },
    /**
     * obj.files  修改filed字段值
     * obj.files.value 被修改字段的值
     * obj.type 状态 add push del 删除
     */
    UP_myLikeList(state, obj) {
      // 新增
      if (obj.type == 'add') {
        state.myLikeList[obj.filed].push(obj.value)
      }
      // 清空
      else if (obj.type == 'clear') {
        state.myLikeList = {
          posts: [],
          comments: [],
        }
      }
      // 减少
      else {
        let index = state.myLikeList[obj.filed].indexOf(obj.value)
        state.myLikeList[obj.filed].splice(index, 1)
      }
    },

    UP_isFromComment(state, value) {
      state.isFromComment = value
    },
    UP_fromCommentId(state, value) {
      state.fromCommentId = value
    },
    UP_postsList(state, obj) {
      /**
       * obj.data 添加某个文章
       * obj.files id 修改某个相等id下的filed字段值++
       * obj.files.value 后面可作为被修改字段的值
       * obj.files.type add ++  reduce --
       */
      if (obj.data) {
        // 判断是否已经存在
        let isHas = state.postsList.some(item => item.id == obj.data.id)
        !isHas && state.postsList.push(obj.data)
      } else {
        if (obj.fileds.type == 'clear') {
          state.postsList = []
        }
        // 数组里的直接进行++
        let whiteNumberArr = ['likeNum', 'commentNum']
        // 遍历后操作 这里主要对评论数/点赞数++
        if (whiteNumberArr.includes(obj.fileds.filed)) {
          state.postsList.forEach(item => {
            if (item.id == obj.fileds.id) {
              if (obj.fileds.type == 'add') {
                item[obj.fileds.filed]++
              } else {
                item[obj.fileds.filed]--
              }
            }
          })
        }
        // 其他字段，直接赋值
        else {
          console.log('进来了哈哈哈哈')
          console.log(obj.fileds.filed)
          console.log(obj.fileds)
          state.postsList.forEach(item => {
            if (item.id == obj.fileds.id) {
              item[obj.fileds.filed] = obj.fileds.value
            }
          })
        }
      }
    },
    UP_postId(state, value) {
      state.postId = value
    },
  },
  actions: {},
}

const imgInfo = {
  namespaced: true,
  state: () => ({
    myLikeList: {
      imgs: [],
    },
  }),
  getters: {
    myLikeImgList: state => {
      return state.myLikeList.imgs
    },
  },
  mutations: {
    UP_LIKELIST(state, obj) {
      // 新增
      if (obj.type == 'add') {
        let index = state.myLikeList.imgs.findIndex(
          item => item.id == obj.value.id
        )
        console.log(index)
        if (index >= 0) {
          state.myLikeList.imgs[index] = {
            id: obj.value.id,
            num: obj.value.num,
            isLiked:true
          }
        } else {
          state.myLikeList.imgs.push({
            id: obj.value.id,
            num: obj.value.num,
            isLiked:true
          })
        }
      }
      // 清空
      else if (obj.type == 'clear') {
        state.myLikeList = {
          imgs: [],
        }
      }
      // 减少
      else {
        let index = state.myLikeList.imgs.findIndex(
          item => item.id == obj.value.id
        )
        if(index >= 0) {
          state.myLikeList.imgs[index].num--
          state.myLikeList.imgs[index].isLiked = false
        } 
      }
    },
  },
  actions:{
    'ACT_UP_LIKELIST'({ commit }){
      commit('UP_LIKELIST', {
        type:'clear'
      })

    }
  }
}

const store = createStore({
  plugins: [
    createPersistedState({
      key: 'encryptionStore',
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  modules: {
    headInfo: headerInfo,
    userInfo: userInfo,
    postInfo: postInfo,
    imgInfo: imgInfo,
  },
})

export default store
