/*
 * @Author: your name
 * @Date: 2022-03-15 22:30:14
 * @LastEditTime: 2022-05-07 16:36:19
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\router\index.js
 */
import { createRouter, createWebHashHistory, useRouter } from 'vue-router'
import { getStorage } from '../utils/storage'
import { isLogin, updateLoginShow, loginOut } from '@/utils/user'
import { getUserInfo } from '@/api/api.js'
import { watch } from 'vue'

const home = () => import('../pages/home/home')
const search = () => import('../pages/search/search')

const guestbook = () => import('../pages/guestbook/guestbook')
const links = () => import('../pages/links/links')
const posts = () => import('../pages/posts/posts')
const tag = () => import('../pages/tag/tag')
const editor = () => import('../pages/editor/index')
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
  },
  {
    path: '/index',
    component: home,
    name: 'index',
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    path: '/guestbook',
    component: guestbook,
    name: 'guestbook',
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    path: '/links',
    component: links,
    name: 'links',
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    path: '/posts/:id',
    name: 'posts',
    component: posts,
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    path: '/tag/:id',
    name: 'tag',
    component: tag,
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    path: '/photo',
    name: 'photo',
    meta: {
      keepAlive: true,
    },
    component: () => import('../pages/photo/index.vue'),
  },
  {
    path: '/phoDetail/:id?',
    name: 'phoDetail',
    meta: {
      keepAlive: false, //设置页面是否需要使用缓存
    },
    component: () => import('../pages/phoDetail/index.vue'),
  },
  {
    path: '/user/:id?',
    name: 'user',
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
    component: () => import('../pages/user/index.vue'),
    beforeEnter: async (to, from, next) => {
      console.log('bhfahgsdjadghasssssssssss')
      // 别人的主页可随便进
      if (to.params.id) {
        let { code } = await getUserInfo({ userId: to.params.id })
        if (code == 404) {
          if (!from.name) {
            next()
          }
          return
        } else {
          next()
        }
        return
      }

      // 未登录
      if (!isLogin()) {
        console.log('未登录')
        // 当前刷新页面返回首页
        if (!from.name) {
          console.log('当前刷新页面返回首页')
          next('/index')
        }

        return
      }

      //登陆情况
      let { code } = await getUserInfo()
      if (code === 403) {
        loginOut()
        return
      }
      next()
    },
  },
  {
    path: '/search/:keyword?',
    component: search,
    name: 'search',
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
    beforeEnter: (to, from, next) => {
      console.log(to)
      if (to.params.keyword == '') {
        next('/index')
      } else {
        next()
      }
    },
  },
  {
    path: '/404',
    name: 'noFound',
    component: () => import('@/pages/404'),
    meta: {
      keepAlive: false, //设置页面是否需要使用缓存
    },
  },
  // {
  //   path: '/editor',
  //   component: editor,
  //   meta: {
  //     keepAlive: true, //设置页面是否需要使用缓存
  //   },
  //   beforeEnter(to, from, next) {
  //     console.log(to.query.pwd)
  //     if (to.query.pwd != '0000') {
  //       next('/index')
  //     } else {
  //       next()
  //     }
  //   },
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, saved) {
    if (saved) {
      // 页面刷新数据获取比较慢，高度无法撑开，导致定位失败，如果页面刷新直接回顶部就行
      if (!to.meta.isLoad) {
        return {
          top: 0,
        }
      }
      // 页面元素加载成功后给当前路由设置meta.isLoad = true,后续再返回就可以保留原来位置
      else {
        return saved
      }
    } else {
      return {
        top: 0,
      }
    }
  },
})

import NProgress from 'nprogress'

//全局进度条的配置
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 1000, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
})

import 'nprogress/nprogress.css'

router.beforeEach((to, from) => {
  NProgress.start()
  if (!to.name) {
    return { name: 'noFound' }
  }
  return true
})

router.afterEach(() => {
  NProgress.done()
})

export default router
