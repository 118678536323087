<template>
  <el-dialog
    v-model="show"
    :modal="true"
    :title="btnTitle"
    :width="screenWidth <= 600 ? '80%' : '22%'"
    top="25vh"
    :before-close="handleClose"
  >
    <!-- 登陆 -->

    <div v-show="btnTitle === '登陆'">
      <Login v-model:btnTitle="btnTitle" @getWxEr="getWxEr()" ref="Login">
      </Login>
    </div>
    <div v-show="btnTitle === '注册'">
      <!-- 注册 -->
      <Register v-model:btnTitle="btnTitle" ref="Register"> </Register>
    </div>

    <div v-show="btnTitle === '微信扫一扫'">
      <!-- 微信 -->
      <Wxogin
        v-model:btnTitle="btnTitle"
        :img="img"
        :count="count"
        :isNone="isNone"
        @getWxEr="getWxEr"
        :loadingFlag="loadingFlag"
      >
      </Wxogin>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="UP_LOGINSHOW(false)">取消</el-button>
        <el-button
          v-if="btnTitle == '登陆' || btnTitle == '注册'"
          type="primary"
          @click="debounce(submit, 300)"
          >{{ btnTitle }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElNotification } from "element-plus";
import Cookies from "js-cookie";
import { setStorage } from "./../../utils/storage";
import Login from "./login.vue";
import {
  login,
  getWxErImg,
  getQrStatus,
  wxToken,
  register,
} from "../../api/api.js";
import Register from "./register.vue";
import Wxogin from "./Wxogin.vue";

import { defineComponent, ref, computed, onMounted } from "vue";
import { debounce } from "../../utils/public";
import { isLogin } from "../../utils/user";

import { ElMessageBox } from "element-plus";

import { useStore, mapMutations } from "vuex";

import useScreen from "../../hooks/useScreen.js";
import { Promise } from "q";

export default defineComponent({
  props: ["dialogVisible"],
  components: {
    Login,
    Register,
    Wxogin,
  },
  setup(props, context) {
    const store = useStore();
    // 获取实时屏幕宽度
    let { screenWidth } = useScreen();
    let btnTitle = ref("登陆");
    const Register = ref(null);
    const Login = ref(null);
    let img = ref("");
    let qrId = ref(null);
    let count = ref(120);
    let isNone = ref(false);
    let loadingFlag = ref(true);
    let show = computed(() => {
      return props.dialogVisible;
    });

    // 获取二维码状态
    let getQrStatusFn = async (id) => {
      return new Promise((rel, ret) => {
        let timer = setInterval(async () => {
          if (isLogin()) {
            clearInterval(timer);
            console.log("已经登陆过了");
            return;
          }
          count.value--;
          let { status, openid } = await getQrStatus({ id: qrId.value });
          if (status == 1) {
            clearInterval(timer);
            rel({ status, openid });
            count.value = 120;
          }
          if (count.value == 0) {
            clearInterval(timer);
            rel({ status, openid });
            count.value = 120;
          }
        }, 1000);
      });
    };

    // 微信登陆
    let wxLogin = (openid) => {
      wxToken({ openid }).then((res) => {
        store.commit("userInfo/UP_USERNAME", res.data.user.username);
        store.commit("userInfo/UP_AVATAR", res.data.user.avatar);
        store.commit("userInfo/UP_TOKEN", res.data.token.access_token);
        store.commit("userInfo/UP_ISLOGIN", true);
        store.commit("userInfo/UP_LOGINSHOW", false);
        setStorage("token", res.data.token.access_token);
        Cookies.set("name", "value");
      });
    };

    // 获取二维码
    let getWxEr = async () => {
      if (count.value == 120) {
        isNone.value = false;
        loadingFlag.value = true;
        let { data, code } = await getWxErImg();
        if (code != 20000) {
          ElNotification({
            type: "error",
            title: "通知",
            message: "二维码获取失败",
            position: "bottom-right",
            offset: 100,
          });
          isNone.value = true;
          return;
        }
        // 获取二维码地址、id
        let { src, id } = data;
        img.value = src;
        qrId.value = id;
        loadingFlag.value = false;
        // 轮询获取二维码状态
        let { status, openid } = await getQrStatusFn();
        if (status === 0) {
          isNone.value = true;
          ElNotification({
            type: "error",
            title: "通知",
            message: "二维码过期了",
            position: "bottom-right",
            offset: 100,
          });
        } else {
          if (isLogin()) {
            console.log("重复登陆");
            return;
          }
          await wxLogin(openid);
          ElNotification({
            type: "success",
            title: "通知",
            message: "登陆成功！",
            position: "bottom-right",
            offset: 100,
          });
        }
      }
    };

    // 关闭modal
    const handleClose = () => {
      store.commit("userInfo/UP_LOGINSHOW", false);
    };

    // 登陆/注册
    const submit = async () => {
      if (btnTitle.value == "登陆") {
        console.log("登陆");
        // 获取账号
        let username = Login.value.formLabelAlign.username.trim();
        // 获取密码
        let password = Login.value.formLabelAlign.password.trim();

        if (username.trim() == "" || password.trim() == "") {
          ElNotification({
            type: "error",
            title: "通知",
            message: "条件不足！",
            position: "bottom-right",
            offset: 100,
          });
          return;
        }

        login({
          email: username,
          password,
        })
          .then((res) => {
            console.log(res);
            store.commit("userInfo/UP_USERNAME", res.data.user.username);
            store.commit("userInfo/UP_AVATAR", res.data.user.avatar);
            store.commit("userInfo/UP_TOKEN", res.data.token.access_token);
            store.commit("userInfo/UP_ISLOGIN", true);
            store.commit("userInfo/UP_LOGINSHOW", false);
            setStorage("token", res.data.token.access_token);
            Cookies.set("name", "value");
            ElNotification({
              type: "success",
              title: "通知",
              message: "登录成功！",
              position: "bottom-right",
              offset: 100,
            });
          })
          .catch((err) => {
            ElNotification({
              type: "error",
              title: "通知",
              message: "登录失败！",
              position: "bottom-right",
              offset: 100,
            });
          });
      } else {
        console.log("注册");
        console.log(Register.value);
        console.log(Login.value);
        // 获取账号
        let email = Register.value.formLabelAlign.username;
        // 获取密码
        let password = Register.value.formLabelAlign.password;
        let code = Register.value.formLabelAlign.code;
        let avatar = Register.value.formLabelAlign.avatar;
        let ruleFormRef = Register.value.ruleFormRef;
        let headImage = Register.value.headImage;
        let username = Register.value.formLabelAlign.tickname;

        // 表单校验
        ruleFormRef.validate((valid, fields) => {
          console.log(fields);
          if (valid) {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            formData.append("code", code);
            formData.append("avatar", avatar);
            formData.append("username", username);
            formData.append("file", headImage);
            register(formData, {
              "Content-Type": "multipart/form-data",
            })
              .then((res) => {
                console.log("可能成功了");
                console.log(res);
                if (res.code === 6100) {
                  store.commit("userInfo/UP_USERNAME", res.data.user.username);
                  store.commit("userInfo/UP_AVATAR", res.data.user.avatar);
                  store.commit(
                    "userInfo/UP_TOKEN",
                    res.data.token.access_token
                  );
                  store.commit("userInfo/UP_ISLOGIN", true);
                  store.commit("userInfo/UP_LOGINSHOW", false);
                  setStorage("token", res.data.token.access_token);

                  ElNotification({
                    type: "success",
                    title: "通知",
                    message: "注册成功！",
                    position: "bottom-right",
                    offset: 100,
                  });
                  return;
                }

                if (res.code === 333) {
                  ElNotification({
                    type: "error",
                    title: "通知",
                    message: "验证码错误或者过期！",
                    position: "bottom-right",
                    offset: 100,
                  });
                  return;
                }

                if (res.code === 6001) {
                  ElNotification({
                    type: "error",
                    title: "通知",
                    message: "邮箱已经被注册！",
                    position: "bottom-right",
                    offset: 100,
                  });
                  return;
                }
              })
              .catch((err) => {
                console.log("失败了");
                console.log(err);
              });
          } else {
            console.log("error submit!");
            return false;
          }
        });
        return;
      }
    };

    return {
      screenWidth,
      handleClose,
      debounce,
      show,
      submit,
      Login,
      Register,
      btnTitle,
      getWxEr,
      count,
      isNone,
      loadingFlag,
      ...mapMutations("userInfo", ["UP_LOGINSHOW"]),
      img,
    };
  },
});
</script>

<style>
.el-dialog {
  background-color: var(--white_divBgc) !important;
  min-width: 300px;
  width: 450px !important;
  border-radius: 5px !important;
}

.el-dialog__title {
  color: var(--white_divFc_light) !important;
}

.tips {
  padding: 10px;
}
.el-ialog {
  border: 1px solid red;
}

@media screen and (max-width: 768px) {
  .el-dialog {
    background-color: var(--white_divBgc) !important;
    width: 90vw !important;
    border-radius: 5px !important;
  }
}
</style>
