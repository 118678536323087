/*
 * @Author: your name
 * @Date: 2022-03-11 17:10:41
 * @LastEditTime: 2022-05-16 14:19:16
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\main.js
 */
// 不再是vue的构造函数 引入一个名为createApp的工厂函数
import { createSSRApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head'
const head = createHead()
console.log(head)
import router from './router'
import ElementPlus from 'element-plus'
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/solarized-light.css'
import './assets/css/font.css'
import Vuex from 'vuex'
import store from './store'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons'
import './assets/style/font/iconfont.css'
// 自定义指令
import directive from './plugin/index'
// 动画库
import animated from 'animate.css'

import MdEditor from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import './assets/style/main.css'

import '@/assets/style/index.scss'
import VueParticles from 'vue-particles'
//vue-preview 开始
import vue3PreviewImage from 'vue3-preview-image'

// import '@/assets/icon/index.js'

import SvgIcon from '@/components/svgIcon/index.vue'

let app = createSSRApp(App)
// 统一注册Icon图标
for (const iconName in ElIconModules) {
  if (Reflect.has(ElIconModules, iconName)) {
    const item = ElIconModules[iconName]
    app.component(iconName, item)
  }
}
directive(app)

// 引入当前svg目录下的文件、不遍历子目录、匹配以'.svg'为结尾的文件
const req = require.context('@/assets/icon/svg', false, /\.svg$/)
// 相当于 req.keys().forEach(key => req(key)), req.keys()是匹配到的svg文件的路径数组
const requireAll = requireContext => requireContext.keys().map(requireContext)
// 得到一个完整解析的module数组
requireAll(req)

app.use(router)
app.use(animated)
app.use(ElementPlus)
app.use(head)
app.use(VueHighlightJS)
app.use(store)
app.use(VueParticles)
// defalut install
app.use(vue3PreviewImage)

console.log(app)

router.isReady().then(() => {
  app.component('MdEditor', MdEditor)
  app.component('svg-icon', SvgIcon)
  app.mount('#app')
  window.head = head
})

// Vue3 全局挂载系统名称
app.config.globalProperties.$systemName = ''

// 防止用户篡改storage的值
// window.addEventListener('storage', (e) => {
//   console.log(1433223);
//   localStorage.setItem(e.key, e.oldValue);
// });
