<template>
  <div
    class="cus-loading"
    :style="{ position: isApp ? 'fixed' : 'absolute', 'z-index': zIndex }"
    @click.prevent.stop
    @touchstart.prevent.stop
    @touchmove.prevent.stop
  >
    <div v-if="isApp" class="sk-chase">
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
      <div class="sk-chase-dot"></div>
    </div>
    <div
      v-else
      class="noApp"
      :style="{
        'align-items': zIndex == 999 ? 'flex-start' : 'center',
        padding: zIndex == 999 ? '100px 0' : '0',
      }"
    >
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>

    <div v-if="isApp" class="cus-loading-cont-txt">
      &nbsp;&nbsp;&nbsp;内容稍后到...
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    isApp: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 99,
    },
  },
  setup(props) {
    let flag = ref(true);
    let iconFlag = ref(true);
    let txtFlag = ref(true);
    let title = ref("&nbsp;&nbsp;&nbsp;客官请稍等...");
    let open = () => {
      this.flag = true;
    };
    let close = () => {
      this.flag = false;
    };
    // 可以绑定这个阻止默认行为和冒泡，上边直接使用的修饰符
    let stopPropagation = (e) => {
      e.stopPropagation();
      e.preventDefault();
      return false;
    };
    return {
      flag,
      iconFlag,
      txtFlag,
      title,
      open,
      close,
      stopPropagation,
    };
  },
};
</script>

<style  >
.noApp {
  width: 100%;
  height: 100%;
  background-color: var(--white_divBgc_light);
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  margin: 0px auto;
  width: 100px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: var(--white_divFc);
  height: 100%;
  width: 1px;
  margin: 2px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.cus-loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white_divBgc);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cus-loading-cont-txt {
  margin-top: 30px;
  color: var(--white_divFc);
  font-size: 14px;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  color: var(--white_divFc);
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
  color: var(--white_divFc);
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--white_divFc);
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>
