/*
 * @Author: your name
 * @Date: 2022-03-30 18:33:07
<<<<<<< HEAD
<<<<<<< HEAD
 * @LastEditTime: 2022-05-06 17:18:23
=======
 * @LastEditTime: 2022-03-31 17:36:23
>>>>>>> dev
=======
 * @LastEditTime: 2022-04-06 15:36:45
>>>>>>> dev
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\hooks\useScreen.js
 */
import { onMounted, watch, ref } from 'vue' 
import { getScreenWidth } from '@/utils/public'
// import rem from '@/utils/rem.js'

export default function () {
  // 屏幕宽度
  let screenWidth = ref(document.body.clientWidth)
  // 轮播图高度
  let flexScreen = ref('')
  // 监听屏幕宽度设置轮播图高度
  watch(
    screenWidth,
    (n, o) => {
      switch (true) {
        case n <= 767:
          flexScreen.value = 'mob'
          break
        case n >= 768 && n <= 1199:
          flexScreen.value = 'pad'
          break
        case n >= 1200:
          flexScreen.value = 'pc'
          break
        default:
          break
      }
    },
    {
      immediate: true,
    }
  )

  onMounted(async () => {
    // 监听屏幕变化，返回屏幕尺寸
    getScreenWidth(width => {
      screenWidth.value = width
    })
  })

  return { flexScreen, screenWidth }
}
