/*
 * @Author: your name
 * @Date: 2022-03-18 10:07:16
 * @LastEditTime: 2022-05-04 19:30:08
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\utils\user.js
 */

import store from '../store'
import { ElNotification } from 'element-plus'
// 判断是否登陆
export let isLogin = () => {
  let isLogin = store.getters['userInfo/isLogin']
  return isLogin
}

// 退出登录
export let loginOut = () => {
  ElNotification({
    type: 'error',
    title: `失败！`,
    message: `登录过期，请重新登录！`,
    position: 'bottom-right',
    duration: 1000,
  })
  setTimeout(() => {
    store.dispatch('userInfo/ACT_UP_ISLOGIN', false)
    store.dispatch('imgInfo/ACT_UP_LIKELIST')
  }, 1000)
}

// 打开/关闭登陆模态框
export let updateLoginShow = flag => {
  store.commit('userInfo/UP_LOGINSHOW', flag)
}
