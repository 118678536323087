/*
 * @Author: your name
 * @Date: 2022-03-29 17:53:55
 * @LastEditTime: 2022-05-06 13:23:56
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\hooks\useTheme.js
 */
import { onMounted, watch } from 'vue'
import { useGetters } from '@/store/utils/useGetters.js'
import { useStore } from 'vuex'

export default function (fn) {
  let { theme } = useGetters('headInfo', ['theme'])
  let root = document.querySelector(':root')
  watch(
    () => theme.value,
    (n, o) => {
      if (n == 'dark') {
        root.style.setProperty('--white_divBgc', '#333')
        root.style.setProperty('--white_divBgc_light', 'rgb(48, 48, 48)')
        root.style.setProperty('--white_divBgc_light1', 'rgba(0,0,0,.2)')
        root.style.setProperty('--white_divFc', 'rgb(238, 234, 234)')
        root.style.setProperty('--white_divBoc', '#666')
        root.style.setProperty('--white_divHovc', 'rgba(255,255,255,.1)')
        root.style.setProperty('--white_appBgc', 'rgba(0,0,0,.7)')
        root.style.setProperty('--white_divFc_light', 'rgba(255,255,255,.5)')
        root.style.setProperty('--white_nprogress', 'red')
        
        fn && fn()
      } else if (n == 'light') {
        root.style.setProperty('--white_divBgc', '#fff')
        root.style.setProperty('--white_divBgc_light',  'rgb(250, 250, 250')
        root.style.setProperty('--white_divBgc_light1', 'rgb(245,245,245)')
        root.style.setProperty('--white_divFc', '#333')
        root.style.setProperty('--white_divBoc', '#eee')
        root.style.setProperty('--white_divHovc', 'rgb(229, 226, 226)')
        root.style.setProperty('--white_appBgc', 'rgba(0,0,0,.1)')
        root.style.setProperty('--white_divFc_light', 'rgba(0,0,0,.5)')
        root.style.setProperty('--white_nprogress', 'blue')

        fn && fn()
      }
    },
    {
      immediate: true,
    }
  )

  return {
    theme
  }
}


