/*
 * @Author: your name
 * @Date: 2022-03-17 17:46:35
 * @LastEditTime: 2022-03-22 12:57:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\components\header\useLike.js
 */
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { likeCancel, like, isLikedFn } from '../../api/api'
import { useGetters } from "../../store/utils/useGetters.js";
import { onScrollToElement, sleep } from "../../utils/public";
import { isLogin, updateLoginShow } from '../../utils/user'



export default function () {

  // 缓存文章列表
  let { postsList, postId, myLikeList } = useGetters("postInfo", ["postsList", "postId", "myLikeList"]);

  let store = useStore()
  let artilceId = ref(0)

  // 点赞数
  let likeNum = computed(() => {
    let postDetail = postsList.value.filter(
      (item) => item.id == postId.value
    )
    if (postDetail.length == 0) {
      return 0;
    }
    return postDetail[0].likeNum
  });

  // 评论数
  let commentNum = computed(() => {
    let postDetail = postsList.value.filter(
      (item) => item.id == postId.value
    )
    if (postDetail.length == 0) {
      return 0;
    }
    return postDetail[0].commentNum
  });

  // 点赞状态
  let isLikedMe = computed(() => {
    let postDetail = postsList.value.filter(
      (item) => item.id == postId.value
    )
    if (postDetail.length == 0) {
      return 0;
    }
    return postDetail[0].isLikedMe
  });

  // 定位到评论并获取焦点
  let pingFn = () => {
    onScrollToElement(
      "comments",
      async () => {
        let input = document
          .getElementById("comments")
          .getElementsByClassName("textarea-only");
        await sleep(300);
        input[0].focus();
      },
      true
    );
  };

  /**
   * @param {Function} params.filed 修改哪个 posts 文章喜欢 comments 留言评论喜欢
   * @param {string} params.type add 增加 del 删除
   * @param {string} value 增加/删除的值
   * 
   * 需要在那边单独计算
   */
  // 修改store mylikelist
  let updateLikeList = (params) => {
    store.commit('postInfo/UP_myLikeList', {
      filed: params.filed,
      type: params.type,
      value: params.value
    })
  }

  // 修改store postslist
  let updatePostList = (params) => {
    store.commit('postInfo/UP_postsList', {
      fileds: {
        id: params.id,
        filed: params.filed,
        type: params.type,
        value: params.value
      }
    })
  }

  /**取消点赞
   * @param {Function} call 文章列表点赞的回调函数，因为那边的点赞量不是跟据store计算的 需要在那边单独计算
   * @param {array} fileds 修改类型数组
   * @param {string} type 点赞类型 posts 文章 comment 评论/留言
   */
  let cancelLike = (call, fileds, type = 'posts') => {
    if (type == 'posts') {
      if (fileds.length == 2) {
        if (call) {
          // 判断store中是否缓存了该文章
          let isPostList = postsList.value.some(item => item.id == artilceId.value)
          // 没有的话则不修改缓存里的数据  
          if (!isPostList) {
            updateLikeList({ filed: 'posts', type: 'del', value: artilceId.value })
          } else {
            updateLikeList({ filed: 'posts', type: 'del', value: artilceId.value })
            updatePostList({ filed: "likeNum", id: artilceId.value, type: 'reduce' })
          }
        }
        // 有的话就都修改
        else {
          updateLikeList({ filed: 'posts', type: 'del', value: postId.value })
          updatePostList({ filed: "likeNum", id: postId.value, type: 'reduce' })
        }

      }

      // 只修改喜欢列表
      else if (fileds.includes('MylikeList')) {
        updateLikeList({ filed: 'posts', type: 'del', value: postId.value })
      }

      // 只修改文章缓存列表
      else {
        if (call) {
          // 判断store中是否缓存了该文章
          let isPostList = postsList.value.some(item => item.id == artilceId.value)
          // 没有的话则不修改缓存里的数据  
          isPostList && updatePostList({ filed: "likeNum", id: artilceId.value, type: 'reduce' })
        } else {
          updatePostList({ filed: "likeNum", id: artilceId.value, type: 'reduce' })
        }
      }
      // 有回调则执行回调
      call && call('cancel')
    }
  }


  /**  增加点赞
     * @param {Function} call 文章列表点赞的回调函数，因为那边的点赞量不是跟据store计算的 需要在那边单独计算
     * @param {array} fileds 修改类型数组
     * @param {string} type 点赞类型 posts 文章 comment 评论/留言
  */
  let addLike = (call, fileds, type) => {
    // 文章点赞
    if (type == 'posts') {
      // 文章缓存与喜欢列表里的点赞啊量可能都要修改
      if (fileds.length == 2) {
        // 如果有回调则代表是文章列表来的点赞
        if (call) {
          // 判断store中是否缓存了该文章
          let isPostList = postsList.value.some(item => item.id == artilceId.value)
          // 没有的话则不修改缓存里的数据  
          if (!isPostList) {
            updateLikeList({
              filed: 'posts',
              type: 'add',
              value: artilceId.value
            })
          } else {
            updateLikeList({
              filed: 'posts',
              type: 'add',
              value: artilceId.value
            })
            updatePostList({
              filed: "likeNum",
              id: artilceId.value,
              type: 'add'
            })
          }
        }
        // 没有回调则代表是文章详情
        else {
          updateLikeList({ filed: 'posts', type: 'add', value: postId.value })
          updatePostList({ filed: "likeNum", id: postId.value, type: 'add' })
        }
      }

      // 只修改喜欢列表
      else if (fileds.includes('MylikeList')) {
        updateLikeList({ filed: 'posts', type: 'add', value: postId.value })
      }


      // 只修改文章缓存列表
      else {
        updatePostList({ filed: "likeNum", id: postId.value, type: 'add' })
      }

      // 有回调则执行回调
      call && call('add')
    }

  }


  /**  点赞事件
     * @param {Function} call 文章列表点赞的回调函数，因为那边的点赞量不是跟据store计算的 需要在那边单独计算
     * @param {string} type 点赞类型 posts 文章 comment 评论/留言
     * @param {Number} id 文章列表传来的id,为了判断文章列表的文章是否在缓存里
  */
  let likeFn = async (call, type, id) => {

    // 判断是否登陆
    if (!isLogin()) {
      updateLoginShow(true)
      return
    }

    // 先判断是否已经点过赞，先判断本地，再判断数据库
    let isLiked = false

    //有id代表是文章列表触发的点赞，给artilceId赋值
    if (id) {
      isLiked = myLikeList.value[type].some(item => item == id)
      artilceId.value = id
    }
    else {
      isLiked = myLikeList.value[type].some(item => item == postId.value)
      artilceId.value = postId.value
    }
    // 文章点赞
    if (type == 'posts') {
      // 本地验证通过
      if (isLiked) {
        // 取消点赞
        likeCancel({ id: Number(artilceId.value), type: 1 }).then(res => {
          updatePostList({ filed: "isLikedMe", id: artilceId.value, value: false })
          cancelLike(call, ['MylikeList', 'postsList'], type)
        })
      }
      // 不通过
      else {
        // 验证是否点过赞
        let { data: flag } = await isLikedFn({ id: Number(artilceId.value), type: 1 })
        // 没点过
        if (!flag) {
          like({ id: Number(artilceId.value), type: 1 }).then(res => {
            updatePostList({ filed: "isLikedMe", id: artilceId.value, value: true })
            addLike(call, ['MylikeList', 'postsList'], type)
          })
        }
        // 点过赞
        else {
          likeCancel({ id: Number(artilceId.value), type: 1 }).then(res => {
            updatePostList({ filed: "isLikedMe", id: artilceId.value, value: false })
            // 本地没有点赞记录，就不需要减少点赞缓存列表了，修改文章列表就行
            cancelLike(call, ['postsList'], type)
          })
        }
      }

    }
  };

  // 增加评论数
  let addCommemtFn = () => {
    store.commit('postInfo/UP_postsList', {
      fileds: {
        id: postId.value,
        filed: 'commentNum',
        type: 'add'
      }
    })
  }



  return {
    likeNum,
    commentNum,
    isLikedMe,
    likeFn,
    pingFn,
    addCommemtFn,
    updatePostList
  }
}



