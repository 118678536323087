<!--
 * @Author: your name
 * @Date: 2022-03-29 17:59:04
 * @LastEditTime: 2022-04-13 20:33:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\components\search\search.vue
-->
<template>
  <div class="searchDiv">
    <el-icon class="searchBtn" v-if="!isShow" @click="change" :size="18">
      <Search></Search>
    </el-icon>

    <el-button
      class="searchBtnRes"
      v-else
      @click="toSearch()"
      type="text"
      size="default"
    >
      搜索
    </el-button>
    <div class="div" :style="{ width: isShow ? '180px' : '0' }">
      <el-input
        v-model="searchValue"
        class="searchValue"
        size="large"
        placeholder="搜索..."
        :autofocus="false"
        :maxlength="10"
        clearable
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
let router = useRouter();
//搜索内容
let searchValue = ref("");
// 显示控制
let isShow = ref(false);
// 去搜索页
let toSearch = () => {
  if (searchValue.value.trim() == "") {
    change();
    searchValue.value == "";
  } else {
    router.push({
      path: `/search/${searchValue.value}`,
    });
    change();
  }
};
let change = () => {
  isShow.value = !isShow.value;
};
</script>

<style lang="scss" >
.searchDiv {
  display: flex;
  align-items: center;
  height: 40px;
  flex-flow: row; /*伸缩项目单行排列*/
  position: relative;
  .div {
    height: 40px;
    position: absolute;
    right: 50px;
    transition: all 0.3s;
    box-sizing: border-box;
    overflow: hidden;
  }
  .searchValue {
    width: 100%;
    height: 100%;
    border: none !important;
  }
  .searchBtn {
    width: 50px !important;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchBtnRes {
    width: 50px !important;
    height: 100% !important;
    flex-shrink: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--white_divBoc) !important;
    border-left: none !important;
    border-radius: 0 !important;
    color: var(--white_divFc) !important;
    font-size: 12px;
  }
}

.el-input__inner {
  box-shadow: 0 0 0 1px var(--white_divBoc) inset !important;
  color: var(--white_divFc) !important;
}

@media screen and (max-width: 768px) {
  .searchDiv {
    margin-right: 10px;
  }
}
</style>