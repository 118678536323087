/*
 * @Author: your name
 * @Date: 2022-03-11 17:10:41
 * @LastEditTime: 2022-06-01 15:06:25
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\api\api.js
 */
import { get, post } from './http' //连接接口文件
export const getTest = p => get('', p); //防止eslint报错
export const postTest = p => post('', p); //防止eslint报错
export const login = p => post('/user/token', p, false); //登陆
export const getLikedPost = p => get('/getLikedPost', p); //获取轮播
export const getPosts = p => get(`/posts/${p.id}`, p); //获取文章
export const getTags = p => get('/getTags', p); //获取轮播
export const postsDetail = id => get(`/postsDetail/${id}`); //获取文章详情
export const getComments = data => get(`/postComment`, data); //获取评论详情
export const getNewReply = data => get(`/getNewReply`, data); //获取评论详情
export const getCommenteply = id => get(`/comment/reply?commentId=${id}`); //获取评论回复详情
export const createComment = data => post(`/comment/create`, data, true); //创建评论
export const createPosts = (data, header) => post(`/posts/create`, data, true, header); //创建文章
export const getHotsPost = data => get(`/getHotsPost`, data); //获取热门文章
export const like = data => post(`/like`, data); //点赞
export const likeCancel = data => post(`/like/cancel`, data); //取消点赞
export const isLikedFn = data => post(`/isLiked`, data); //获取是否点赞
export const guestbook = p => get('/guestbook', p); //获取留言
export const searchPosts = data => post(`/posts/search`, data); //获取是否点赞


export const getRandomImg = p => get('/getRandomImg', p); //获取图片列表
export const getImgDetail = p => get('/getImgDetail', p); //获取图片详情
export const getImgComment = p => post('/getImgComment', p); //获取图片详情
export const createFollow = p => post('/createFollow', p); //获取图片详情
export const isFollowed = p => post('/isFollowed', p); //获取图片详情
export const getUserInfo = p => post('/getUserInfo', p); //获取图片详情
export const getImgById = p => post('/getImgById', p); //获取图片详情
export const getPostById = p => post('/getPostById', p); //获取图片详情

export const getWxErImg = p => get('/test', p); //获取图片详情
export const getQrStatus = p => get('/getQrStatus', p); //获取图片详情
export const wxToken = p => get('/wxToken', p); //获取图片详情
export const getEmailCode = p => get('/getEmailCode', p); //发送验证码
export const register = (data,header) => post('/user/register', data,true,header); //注册











