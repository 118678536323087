import axios from 'axios'
// import router from 'vue-router'
import QS from 'qs';

import { loginOut } from '../utils/user';

import {
	ElNotification
} from 'element-plus'

import store from "../store/index";
import { getStorage } from '../utils/storage'
let baseURL
// 生产环境
if (process.env.NODE_ENV == 'development') {
	// baseURL = 'http://tingtelzz.natapp1.cc/api/v1/';
	baseURL = 'https://boke.baymax.top/api/v1/';
	// baseURL = 'http://192.168.2.106:7001/api/v1/';

}
// debug
else if (process.env.NODE_ENV == 'debug') {
	baseURL = 'http://tingtelzz.natapp1.cc/api/v1/';
	// baseURL = 'http://192.168.2.104:7001/api/v1/';
	
}
// 线上
else if (process.env.NODE_ENV == 'production') {
	// baseURL = 'http://tingtelzz.natapp1.cc/api/v1/';

	baseURL = 'https://boke.baymax.top/api/v1/';
}

let token = getStorage('token')

//创建axios的一个实例 
var instance = axios.create({
	baseURL: baseURL, //接口统一域名
	timeout: 6000, //设置超时
	headers: {
		'authorization': `Bearer ${token}`,
		post: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
		}
	}
})

instance.defaults.withCredentials = true

// 一、请求拦截器
instance.interceptors.request.use(function (config) {
	// 操作
	return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});


// 响应拦截器
instance.interceptors.response.use(
	response => {
		// 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
		// 否则的话抛出错误
		if (response.status === 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	// 服务器状态码不是2开头的的情况
	// 这里可以跟你们的后台开发人员协商好统一的错误状态码    
	// 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
	// 下面列举几个常见的操作，其他需求可自行扩展
	error => {
		if (error.response.status) {
			switch (error.response.status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。                
				case 401:
					try {
						// 退出登录
						loginOut()
					} catch (error) {
						console.log(error);
					}

					// router.replace({
					// 	path: '/login',
					// 	query: {
					// 		redirect: router.currentRoute.fullPath
					// 	}
					// });
					break;

				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面                
				case 403:
					loginOut()
					alert({
						message: '登录过期，请重新登录',
						duration: 1000,
						forbidClick: true
					});
					// 清除token
					localStorage.removeItem('token');
					// store.commit('loginSuccess', null);
					// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
					setTimeout(() => {
						// router.replace({
						// 	path: '/login',
						// 	query: {
						// 		redirect: router.currentRoute.fullPath
						// 	}
						// });
					}, 1000);
					break;

				// 404请求不存在
				case 404:
					break;
				// 其他错误，直接抛出错误提示
				default:
				// alert({
				// 	message: error.response.data.message,
				// 	duration: 1500,
				// 	forbidClick: true
				// });
			}
			return Promise.reject(error.response);
		}
	}
)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		instance.get(
			url, 
			{
			params: params,
			headers: {
				'authorization': `Bearer ${getStorage('token')}`
			}
		}).then(res => {
			resolve(res.data);
		}).catch(err => {
			reject(err.data)
		})
	})
}


/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, flag, headers) {
	return new Promise((resolve, reject) => {
		console.log(flag);
		let p = flag ? params : QS.stringify(params)
		instance.post(url, p, {
			headers: {
				'authorization': `Bearer ${getStorage('token')}`,
				...headers
			}
		}
		)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}

export default instance
