<!--
 * @Author: your name
 * @Date: 2022-03-11 17:10:41
 * @LastEditTime: 2022-06-01 15:58:19
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\components\Account\login.vue
-->
<template>
  <el-form
    :label-position="labelPosition"
    label-width="60px"
    :model="formLabelAlign"
    :rules="baseRules"
  >
    <el-form-item label="邮箱" prop="username">
      <el-input
        v-model="formLabelAlign.username"
        placeholder="请输入邮箱"
      ></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input
        v-model="formLabelAlign.password"
        placeholder="请输入密码"
        type="password"
      ></el-input>
    </el-form-item>
  </el-form>
  <div class="toReg">
    <div class="toWxLogin">
      <div style="width: 60px">其他：</div>
      <div class="btn">
        <svg-icon
          :iconClass="'wechat'"
          style="font-size: 24px"
          @click="toWx()"
        ></svg-icon>
      </div>
    </div>
    <div class="toZhuce">
      <el-button type="text"  @click="toReg()">去注册</el-button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  name: "login",
  props: ["btnTitle"],
  setup(props, context) {
    let formLabelAlign = reactive({
      username: "",
      password: "",
    });

    let baseRules = {
      username: [{ required: true, message: "请输入账号", trigger: "blur" }],
      password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    };

    let toReg = () => {
      context.emit("update:btnTitle", "注册");
    };

    let toWx = () => {
      context.emit("update:btnTitle", "微信扫一扫");
      context.emit("getWxEr");
    };

    return {
      labelPosition: "left",
      formLabelAlign,
      baseRules,
      toReg,
      toWx,
    };
  },
};
</script>

<style lang="scss"  >
.el-form-item__error {
  margin-top: 2px;
  padding: 2px 0 !important;
  line-height: none;
  position: static !important;
}
::v-deep .el-input__inner {
  color: var(--white_divFc) !important;
}
input:focus {
  border: 1px solid var(--white_divFc_light) !important;
}

//  .el-input {
//   height: 150px !important ;
// }
.el-input__inner {
  border: 1px solid var(--white_divBoc) !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
}

.toReg {
  cursor: pointer;
  // color: deepskyblue;
  color: var(--white_divFc_light);
  display: flex;
  justify-content: center;
  flex-direction: column;
  .toWxLogin {
    display: flex;
  padding-left: 10px;

  }
  .toZhuce{ 
    margin-top: 10px;
    margin-right: 150px;
    // border: 1px solid red;
  }
  .btn {
    flex: 1;
    // border: 1px solid red;
    display: flex;
    align-items: center;
  }
}
.el-form-item__label {
  color: var(--white_divFc_light) !important;
}
.el-input--default {
  box-shadow: 0 0 0 0px red inset !important;
  color: var(--white_divFc_light) !important;
}
</style>
