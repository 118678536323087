/*
 * @Author: your name
 * @Date: 2022-03-14 13:57:28
<<<<<<< HEAD
 * @LastEditTime: 2022-05-02 13:52:35
=======
 * @LastEditTime: 2022-04-08 16:31:02
>>>>>>> dev
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\utils.js\public.js
 */

// 滚动插件
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

//判断一个元素是否在可视区域内
function isInVisibleArea(elem) {
  if (!elem || !elem.getBoundingClientRect) return false
  var rect = elem.getBoundingClientRect()
  if (
    rect.top < window.innerHeight &&
    rect.bottom > 0 &&
    rect.left < window.innerWidth &&
    rect.right > 0
  ) {
    return true
  } else {
    return false
  }
}

export function deepClone(obj) {
  if (typeof obj != 'object' || !obj) {
    return obj
  }

  let result = obj instanceof Array ? [] : {}

  for (let key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      // 为了成功拷贝深层次对象，这里需要用递归
      result[key] = deepClone(obj[key])
    }
  }

  return result
}

// 滚动到指定节点
/**
 *
 * @param {*} elementId 元素节点
 * @param {*} fn 回调函数
 * @param {*} flag 回调执行时机,false立即执行 true元素进入可是区域内执行
 */
export function onScrollToElement(elementId, fn, flag = false) {
  let eleMent = document.getElementById(elementId)
  scrollIntoView(eleMent)
  // eleMent.scrollIntoView({
  //   behavior: 'smooth',
  //   block: 'center',
  // })
  if (!flag) {
    fn && fn()
  } else {
    // 注册滚动事件
    window.onscroll = function () {
      let aa = isInVisibleArea(eleMent)
      if (aa) {
        fn && fn()
        // 清空事件
        window.onscroll = null
      }
    }
  }
}

/**
 * 强制等待
 * @param {Number} wait 等待时间/毫秒
 * @returns
 */
export const sleep = wait => {
  return new Promise(rel => {
    setTimeout(() => {
      rel()
    }, wait)
  })
}

// 监听图片是否加载完成
/**
 *
 * @param {dom} img 图片元素节点
 * @param {*} callback //回调函数
 */
export const imgLoad = (img, callback, len) => {
  return new Promise((rel, ret) => {
    var timer = setInterval(function () {
      if (img.complete) {
        // img.style.height = 'auto'
        if (len == 4) {
          img.style.border = '1px solid blue'
        }
        clearInterval(timer)
        rel(img.clientHeight)
      }
    }, 50)
  })
}

// 监听图片是否加载完成
/**
 *
 * @param {dom} img 图片元素节点
 * @param {*} callback //回调函数
 */
export const imgArrLoad = imgArr => {
  return new Promise(async (rel, ret) => {
    for (let i = 0; i < imgArr.length; i++) {
      await imgLoad(imgArr[i], null, imgArr.length)
    }
    rel(1)
  })
}

// 防抖
export const debounce = (() => {
  let timer = null
  return (fn, wait) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn()
    }, wait)
  }
})()

// 防抖
export const throllte = (() => {
  let timer = null
  let count = 0
  return (fn, wait,flag = false) => {
    // 有时间直接return
    if (timer) {
      return
    } else {
      // count为0 立即执行
      if (count === 0 && flag) {
        fn()
        count++
      } 
      // count不为0开启定时器
      else {
        timer = setTimeout(() => {
          fn()
          // 结束后清除定时器
          timer = null
        }, wait)
      }
    }
  }
})()

// 页面刷新之前执行fn回调
export const onRefresh = fn => {
  // 判断是否是safari浏览器
  function isSafari() {
    var ua = navigator.userAgent.toLowerCase()
    if (
      ua.indexOf('applewebkit') > -1 &&
      ua.indexOf('mobile') > -1 &&
      ua.indexOf('safari') > -1 &&
      ua.indexOf('linux') === -1 &&
      ua.indexOf('android') === -1 &&
      ua.indexOf('chrome') === -1 &&
      ua.indexOf('ios') === -1 &&
      ua.indexOf('browser') === -1
    ) {
      return true
    } else {
      return false
    }
  }

  let fnName = !isSafari() ? 'beforeunload' : 'pagehide'
  window.addEventListener(fnName, function (e) {
    fn()
  })
}

// 监听屏幕宽度并返回
export let getScreenWidth = fn => {
  window.addEventListener('resize', function (e) {
    fn(document.body.clientWidth)
  })
}

export let getTimeState = () => {
  // 获取当前时间
  let timeNow = new Date()
  // 获取当前小时
  let hours = timeNow.getHours()
  // 设置默认文字
  let state = ``
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    state = `早上好`
  } else if (hours > 10 && hours <= 14) {
    state = `中午好`
  } else if (hours > 14 && hours <= 18) {
    state = `下午好`
  } else if (hours > 18 && hours <= 24) {
    state = `晚上好`
  }
  return state
}
