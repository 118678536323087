<!--
 * @Author: baymax-arch 1625750783@qq.com
 * @Date: 2022-05-16 09:59:44
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @LastEditTime: 2022-05-22 14:25:09
 * @FilePath: \MyBoke\src\components\svgIcon\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>
<script setup>
import { computed, defineProps } from "vue";

let props = defineProps({
  iconClass: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: "",
  },
});

let iconName = computed(() => {
  return `#icon-${props.iconClass}`;
});

let svgClass = computed(() => {
  if (props.className) {
    return `svg-icon ${props.className}`;
  }
  return "svg-icon";
});
</script>
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor; /* 图标填充颜色，currentColor是一个变量，表示当前元素的color值，如当前无指定，则从父元素继承 */
  overflow: hidden;
}
</style>
