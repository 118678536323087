<!--
 * @Author: your name
 * @Date: 2022-03-11 17:10:41
 * @LastEditTime: 2022-05-15 17:41:53
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\components\Account\login.vue
-->
<template>
  <div class="wxDiv">
    <div class="img">
      <img
        :src="img"
        style="width: 180px;"
        :style="{ 'filter': isNone ? 'blur(2px)' : '' }"
      />
      <Loadings v-show="loadingFlag" :isApp="false" />
      <div v-show="isNone" class="modal">已过期</div>
    </div>
    <span class="toUserPwd" @click="toLogin()">账号密码登陆</span>
    <span class="link" v-show="!isNone && !loadingFlag">{{ count }}秒后过期</span>
    <span class="link" v-show="isNone" @click="$emit('getWxEr')">重新获取</span>
  </div>
  <div class="toReg">
    <!-- <el-button type="text" disabled @click="toReg()">去注册</el-button> -->
  </div>
</template>

<script>
import { reactive } from "vue";
import Loadings from "@/components/loading/loading.vue";
export default {
  name: "login",
  props: ["btnTitle", "img", "count", "isNone", "loadingFlag"],
  components: {
    Loadings,
  },
  setup(props, context) {
    let toLogin = () => {
      context.emit("update:btnTitle", "登陆");
    };
    return {
      labelPosition: "left",
      toLogin,
    };
  },
};
</script>

<style lang="scss" scoped >
.img {
  width: 180px !important;
  height: 180px !important;
  min-height: 180px !important;
  position: relative;
  .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
.toUserPwd{
   color: var(--white_divFc_light);
  cursor: pointer;
  margin-top: 8px;
}
.link {
  color: red;
  cursor: pointer;
  margin-top: 8px;
}
.wxDiv {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.el-input__inner {
  color: var(--white_divFc) !important;
}

.el-form-item__label {
}
.toReg {
  cursor: pointer;
  color: deepskyblue;
}
.el-form-item__label {
  color: var(--white_divFc_light) !important;
}
/* class="el-input t" */
.el-input--default {
  box-shadow: 0 0 0 0px red inset !important;
  color: var(--white_divFc_light) !important;
}
</style>
