<!--
 * @Author: your name
 * @Date: 2022-03-13 19:35:22
 * @LastEditTime: 2022-06-01 10:54:17
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\components\footer\footer.vue
-->
<template>
	<div class="footer">
		<div>© 2022 vue3.baymax.top All Rights Reserved.</div>
		<div> 豫ICP备2020024992号-1 </div>
	</div>
</template>

<script>
	export default{
		name:'footer',
		setup(){
			
		}
	}
</script>

<style>
	.footer{
		width: 100%;
		padding: 25px 0;
		height: auto;
		box-sizing: border-box;
		background-color: var( --white_divBgc);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: var(--white_divFc);
		font-size: 16px;
	}
	
	.footer div{
		padding: 4px 0;
	}
</style>
