<template>
  <vue-particles
    v-if="particlesFlag"
    :color="theme == 'dark' ? '#eee' : '#333'"
    :particleOpacity="0.5"
    :particlesNumber="20"
    shapeType="circle"
    :particleSize="3"
    :linesColor="theme == 'dark' ? '#eee' : '#333'"
    :linesWidth="2"
    :lineLinked="true"
    :lineOpacity="0.5"
    :linesDistance="150"
    :moveSpeed="2"
    :hoverEffect="true"
    hoverMode="grab"
    :clickEffect="true"
    clickMode="push"
  >
  </vue-particles>
  <!-- Header -->
  <Header :links="links">
    <template #head_left
      ><span @click="toIndex()">
        {{ logo }}
      </span></template
    >
  </Header>
  <!-- router-view -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component
        :is="Component"
        :key="$route.path"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
  <!-- footer -->
  <Footer></Footer>
  <!-- 返回顶部 -->
  <el-backtop />
  <!-- 登陆注册 -->
  <Account v-model:dialogVisible="loginShow"></Account>
  <!-- 全评loading -->
  <Loading v-if="isLoading" :isApp="true"></Loading>
</template>

<script>
import { ref, provide, onMounted } from "vue";
// import {mapMutations,useStore} from 'vuex'
import { useGetters } from "./store/utils/useGetters.js";
import { useRoute, useRouter } from "vue-router";
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";
import Account from "./components/Account/Account.vue";
import Loading from "./components/loading/loading.vue";
import useTheme from "@/hooks/useTheme.js";
//自定义全局Loading组件
import { ElNotification } from "element-plus";
import { useHead } from "@vueuse/head";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Account,
    Loading,
  },
  setup() {
    let router = useRouter();
    let isLoading = ref(true);
    let particlesFlag = ref(true);

    let { theme } = useTheme(() => {
      particlesFlag.value = false;
      setTimeout(() => {
        particlesFlag.value = true;
      }, 0);
    });
    let changeDark = (e) => {
      isLoading.value = e;
    };

    // 点击logo返回主页
    let toIndex = () => {
      router.push({
        path: `/index`,
      });
    };

    provide("changeDark", changeDark);

    return {
      particlesFlag,
      theme,
      isLoading,
      toIndex,
      ...useGetters("headInfo", ["logo", "links"]),
      ...useGetters("userInfo", ["loginShow"]),
    };
  },
};
</script>

<style>
#particles-js {
  width: 100%;
  /* height: calc(100% - 100px); */
  height: 100vh;
  position: fixed;
  z-index: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
  font-size: 18px;
  background-color: var(--white_appBgc);
}

.isRouterAlive {
  height: 100vh;
}
.dark {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
}

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #666;
}

::-webkit-scrollbar {
  display: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(2, 3, 3, 0.09);
}

::-webkit-scrollbar-track {
  background: hsla(210, 8%, 51%, 0.09);
}

.detail ::-webkit-scrollbar {
  display: block !important;
  width: 8px;
  height: 8px;
}
</style>
