<!--
 * @Author: baymax-arch 1625750783@qq.com
 * @Date: 2022-03-15 22:30:14
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @LastEditTime: 2022-06-01 15:57:36
 * @FilePath: \MyBoke\src\components\Account\register.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A
-->
<template>
  <el-form
    :label-position="labelPosition"
    label-width="70px"
    :model="formLabelAlign"
    :rules="baseRules"
    ref="ruleFormRef"
  >
    <el-form-item required label="头像" prop="avatar">
      <el-upload
        class="avatar-uploader"
        :show-file-list="false"
        v-model="formLabelAlign.avatar"
        :before-upload="beforeAvatarUpload"
      >
        <img
          class="previewImg avatar"
          v-if="formLabelAlign.avatar"
          :src="formLabelAlign.avatar"
        />
        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
      </el-upload>
    </el-form-item>

    <el-form-item required label="昵称" prop="tickname">
      <el-input v-model="formLabelAlign.tickname" placeholder="请输入昵称"></el-input>
    </el-form-item>

    <el-form-item required label="邮箱" prop="username">
      <el-input v-model="formLabelAlign.username" placeholder="请输入邮箱"></el-input>
    </el-form-item>

    <el-form-item class="codeItem" required label="验证码" prop="code">
      <el-input v-model="formLabelAlign.code" placeholder="请输入验证码">
        <template #suffix>
          <el-button
            :disabled="sendRes"
            type="primary"
            @click="sendCode(ruleFormRef)"
          >
            {{ sendRes ? `${second}s后可重新获取` : `获取验证码` }}
          </el-button>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item required label="密码" prop="password">
      <el-input type="password" v-model="formLabelAlign.password" placeholder="请输入密码"></el-input>
    </el-form-item>
  </el-form>
  <div class="toLogin" @click="toLogin()">去登陆</div>
</template>

<script>
import { reactive, ref } from "vue";
import { getEmailCode } from "@/api/api.js";
export default {
  name: "register",
  props: ["btnTitle"],
  setup(props, context) {
    let formLabelAlign = reactive({
      username: "",
      password: "",
      code: "",
      avatar: "",
      tickname: "",
    });

    const ruleFormRef = ref(null);
    let sendRes = ref(false);
    let second = ref(60);

    // 倒计时
    let daoJiShi = () => {
      let timer = setInterval(async () => {
        second.value--;
        if (second.value == 0) {
          clearInterval(timer);
          second.value = 60;
          sendRes.value = false;
        }
      }, 1000);
    };

    const checkCode = (rule, value, callback) => {
      console.log(value);
      if (!value) {
        return callback(new Error("请输入验证码"));
      }
      if (value.length < 6) {
        return callback(new Error("验证码最少6位"));
      }
      if (value.length > 6) {
        return callback(new Error("验证码最多6位"));
      }
      if (typeof value == "number") {
        callback(new Error("验证码只能位数字"));
      } else {
        callback();
      }
    };

    let baseRules = {
      username: [
        {
          required: true,
          type: "email",
          message: "邮箱格式错误",
          trigger: "blur",
        },
        {
          min: 15,
          max: 50,
          message: "邮箱长度有误",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { min: 8, message: "密码不能低于8位", trigger: "blur" },
        { max: 16, message: "密码不能高于16位", trigger: "blur" },
      ],
      avatar: [{ required: true, message: "请上传头像", trigger: "blur" }],
      tickname: [
        { required: true, message: "请输入昵称", trigger: "blur" },
        { max: 6, message: "昵称不能高于6位", trigger: "blur" },
      ],
      code: [
        {
          validator: checkCode,
          trigger: "blur",
        },
      ],
    };

    let headImage = ref("");
    let previewImg = ref("");

    let toLogin = () => {
      console.log(formLabelAlign);
      context.emit("update:btnTitle", "登陆");
    };

    let beforeAvatarUpload = (res) => {
      headImage.value = res;
      formLabelAlign.avatar = window.URL.createObjectURL(res);
    };

    let sendCode = async (el) => {
      try {
        let usernameRes = await el.validateField("username");
        getEmailCode({ email: formLabelAlign.username }).then((res) => {
          console.log(res);
          sendRes.value = usernameRes;
          daoJiShi();
        });
      } catch (e) {
        //TODO handle the exception
        console.log("验证不通过");
        console.log(e);
      }
    };

    return {
      labelPosition: "left",
      formLabelAlign,
      beforeAvatarUpload,
      toLogin,
      previewImg,
      headImage,
      baseRules,
      ruleFormRef,
      sendCode,
      sendRes,
      second,
    };
  },
};
</script>

<style lang="scss" scoped>
.codeItem {
  ::v-deep .el-input__suffix {
    right: 0 !important;
  }
  ::v-deep .el-input__inner {
    padding-right: 0 !important;
  }
  ::v-deep .el-button {
    width: 100% !important;
    float: right;
  }
}

.toLogin {
  cursor: pointer;
  color: deepskyblue;
}
.previewImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
</style>
