<!--
 * @Author: your name
 * @Date: 2022-03-16 09:33:29
 * @LastEditTime: 2022-03-30 12:50:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\components\articleTool\articleTool.vue
-->
<template>
  <div class="articleToolMain">
    <!-- 我是文章操作小工具 -->
    <div
      class="like animate__animated"
      ref="like"
      @click="debounce(likeFn, 200)"
    >
      <i
        :class="isLikedMe && isLogin ? 'icon-elzanpinged' : 'icon-elzanping'"
      ></i>
      <span>{{ likeNum }}</span>
    </div>
    <div class="ping" @click="debounce(pingFn, 200)">
      <i class="icon-elpinglun"></i>
      <span>{{ commentNum }}</span>
    </div>
  </div>
</template>
 
<script>
// 防抖
import { debounce } from "../../utils/public";
import { ref,toRef } from "vue";
import { useGetters } from "../../store/utils/useGetters.js";
import { sleep } from "../../utils/public";
export default {
  props: ["likeNum", "commentNum", "isLikedMe"],
  setup(props, ctx) {
    let like = ref(null);
    let likeFn = async () => {
      ctx.emit("likeFn");
      // 添加动画600毫秒后删除
      like.value.classList.add("animate__pulse");
      await sleep(600);
      like.value.classList.remove("animate__pulse");
    };

    let pingFn = () => {
      ctx.emit("pingFn");
    };

    return {
      debounce,
      likeFn,
      pingFn,
      like,
      ...useGetters("userInfo", ["isLogin"]),
    };
  },
};
</script>

<style lang="scss">
.icon-elzanping:before{
  color: var(--white_divFc) !important;
}
@media (any-hover: hover) {
  .like:hover,
  .ping:hover {
    background-color: var(--white_divHovc);
    -webkit-tap-highlight-color: transparent !important;
  }
}

.like,
.ping {
  // border: 1px solid red;
  padding: 5px 8px;
  display: flex;
  margin-right: 10px;
  box-sizing: border-box !important;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent !important;

  i {
    display: flex;
    flex: 1;
  }
  span {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 2px;
    color: var(--white_divFc);
  }
}

.ping {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}

.like i,
.ping i {
  font-size: 18px;
}
.zanNum {
    color: var(--white_divFc);

  font-size: 10px;
}
.commentNum {
    color: var(--white_divFc);

  font-size: 10px;
}
.articleToolMain {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid red; */
  font-size: 12px;
}
</style>